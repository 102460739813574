export default {
  page: 1,
  data: [
    {
      title: 'Which is not in the following?',
      slug: 'which-is-not-in-the-following',
      description:
        'Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.',
      thumbnail: 'https://via.placeholder.com/150',
    },
    {
      title: 'Basic HTML test',
      slug: 'basic-html-test',
      description:
        'Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.',
      thumbnail: 'https://via.placeholder.com/150',
    },
    {
      title: 'Advanced PHP test',
      slug: 'advanced-php-test',
      description:
        'Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.',
      thumbnail: 'https://via.placeholder.com/150',
    },
  ],
};
